import * as React from 'react'
import {useEffect, useState} from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import catalogue_preview_images from './catalogue_preview_images.json'
import catalogue_overview from './catalogue_overview.json'
import MarkdownToJsx from '../../Components/MarkdownToJsx'
import introduction from './catalogueMarkdown/intro.md'
import {useParams} from 'react-router'
import CatalogueImageSelected from './components/CatalogueImageSelected'
import {CatalogueDetailsInterface} from './Catalogue.types'
import CatalogueJumbotron from './components/CatalogueJumbatron'
import CataloguePreview from './components/CataloguePreview'
import ImageDetails from './components/ImageDetails'
import {Divider} from '@mui/material'
import Breadcrumbs from '@mui/material/Breadcrumbs'
import {Link} from 'react-router-dom'
export default function PageFashion() {
  return (
    <div className="">
      {/* <div className="mb-5">
        <Typography variant="h3" textAlign={'center'} mb={1}>
          Catalogue Previews
        </Typography>
        <CatalogueJumbotron preview_images={catalogue_preview_images} />
      </div> */}

      <Typography variant="h3" textAlign={'center'} my={2}>
        Fashion Photography
      </Typography>
      <Divider />
      <Grid container className="mb-5" justifyContent={'center'}>
        {/* <Grid item xs={11} md={10} textAlign="center">
          <MarkdownToJsx markdown={introduction} title={''} aboutArtists={true} />
        </Grid> */}
        <Grid container alignContent={'center'} justifyContent={'center'} xs={6} md={4} my={2}>
          <img src={require('../../Assets/fashion/cover.jpg')} />
        </Grid>
        <Grid item xs={12} md={8}>
          <Grid container spacing={5} justifyContent={'center'}>
            <MarkdownToJsx markdown={introduction} title={'About the artist'} aboutArtists={true} />
          </Grid>
        </Grid>
        <Grid container spacing={5} justifyContent={'center'} marginTop={1}>
          {catalogue_overview
            .sort((itemA, itemB) =>
              Number(require(`../../Assets/fashion/${itemA.details}`).publishing_year) <
              Number(require(`../../Assets/fashion/${itemB.details}`).publishing_year)
                ? 1
                : -1
            )
            .map((item, index) => (
              <CataloguePreview key={index} catalogue={item} />
            ))}
        </Grid>
      </Grid>
    </div>
  )
}

export const SelectedFashion = () => {
  const test = catalogue_overview
  let {topicId} = useParams()
  const [selectedCatalogue, setSelectedCatalogue] = useState<CatalogueDetailsInterface | null>(null)
  useEffect(() => {
    console.log(topicId)
    if (topicId) {
      console.log(catalogue_overview)
      const getDetails = catalogue_overview.find((item) => item.href == topicId)
      const details = require(`../../Assets/fashion/${getDetails.details}`)
      console.log(details)
      console.log(selectedCatalogue)
      setSelectedCatalogue(details)
    }
  }, [topicId])

  console.log('selectedCatalogue')
  console.log(selectedCatalogue)
  function handleClick(event: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    event.preventDefault()
    console.info('You clicked a breadcrumb.')
  }
  if (selectedCatalogue) {
    return (
      <div className="">
        <Grid container sx={{mt: 3, justifyContent: 'center'}} xs={6} md={4}>
          <Breadcrumbs aria-label="breadcrumb">
            <Link to={`/fashion`}>
              <Typography fontSize={20} fontWeight={500} color="text.primary">
                Fashion
              </Typography>
            </Link>
            <Typography fontSize={20} color="text.primary" style={{textDecoration: 'underline'}}>
              {selectedCatalogue.title}
            </Typography>
          </Breadcrumbs>

          {/* <Grid item xs={12} md={4} sx={{alignSelf: 'center'}}>
            <img
              className="d-block w-100"
              src={require(`../../Assets/fashion/${selectedCatalogue.cover_image_front}`)}
              alt="First slide"
            />
          </Grid> */}
        </Grid>
        <Typography variant="h3" textAlign={'center'} mt={2}>
          {selectedCatalogue.description}
        </Typography>
        <CatalogueImageSelected selectedCatalogue={selectedCatalogue} />
        <Grid container xs={12} md={8} justifyContent={'center'}>
          <Grid item>
            <MarkdownToJsx
              markdown={require(`../../Assets/fashion/${selectedCatalogue.catalogue_introduction}`)}
              title={''}
              aboutArtists={false}
            />
          </Grid>
        </Grid>
      </div>
    )
  } else {
    return <div>HI</div>
  }
}
