import * as React from 'react'
import 'react-responsive-carousel/lib/styles/carousel.min.css' // requires a loader
import {Carousel} from 'react-responsive-carousel'
import {useMediaQuery} from 'react-responsive'
import {CatalogueDetailsInterface} from '../Catalogue.types'
import Grid from '@mui/material/Grid'
import {useState} from 'react'
import ImageDetails from './ImageDetails'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'

interface CatalogueImageSelectedProps {
  selectedCatalogue: CatalogueDetailsInterface
}

const CatalogueImageSelected: React.FC<CatalogueImageSelectedProps> = ({selectedCatalogue}) => {
  const isMobile = useMediaQuery({query: '(max-width: 500px)'})
  const isPortrait = useMediaQuery({query: '(orientation: portrait)'})
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})
  const refImage = React.useRef(null)
  const [selectedImage, setSelectedImage] = useState([])
  const [imageIndex, setImageIndex] = React.useState(0)
  console.log(selectedCatalogue)
  React.useEffect(() => {
    const array = []
    selectedCatalogue.print_on_canvas.forEach((item) => {
      array.push({
        original: require(`../../../Assets/fashion/${selectedCatalogue.path}${item.url}`),
        thumbnail: require(`../../../Assets/fashion/${selectedCatalogue.path}${item.url}`),
        data: item,
        catalogue: selectedCatalogue,
        loading: 'lazy'
      })
    })
    setSelectedImage(array)
  }, [])

  console.log(selectedImage[imageIndex])
  console.log(imageIndex)
  return (
    <Grid container spacing={2} sx={{mt: 3, mb: 7}} justifyContent="center">
      <Grid item xs={12} md={10}>
        <ImageGallery
          items={selectedImage}
          lazyLoad={true}
          thumbnailPosition={'bottom'}
          showThumbnails={isMobile ? false : true}
          showIndex={true}
          ref={refImage}
          slideDuration={700}
          // slideInterval={4000}
          startIndex={0}
          onSlide={() => setImageIndex(refImage.current?.getCurrentIndex())}
        />
      </Grid>
      {/* <Grid item xs={12} md={6}>
        {selectedImage.length !== 0 ? (
          <ImageDetails image={selectedImage[imageIndex]} currentIndex={imageIndex} />
        ) : (
          <></>
        )}
      </Grid> */}
    </Grid>
  )
}

export default CatalogueImageSelected
