import * as React from 'react'
import Grid from '@mui/material/Grid'
import MarkdownToJsx from '../../Components/MarkdownToJsx'
import introductionMarkdown from './impressumMarkdown/introduction.md'

export default function PageImpressum() {
  return (
    <div>
      <Grid container spacing={5} sx={{mt: 3, mb: 7}}>
        <Grid item xs={12} md={12} textAlign="center">
          <MarkdownToJsx markdown={introductionMarkdown} title="Impressum" />
        </Grid>
      </Grid>
    </div>
  )
}
