import React from 'react'
import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  TextareaAutosize,
  TextField,
  MenuItem
} from '@mui/material'
import {Field, reduxForm} from 'redux-form'
import asyncValidate from './asyncValidate'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Button from '@mui/material/Button'
import Select from '@mui/material/Select'

const validate = (values) => {
  const errors = {}
  const requiredFields = ['firstName', 'lastName', 'email', 'favoriteColor', 'notes']
  requiredFields.forEach((field) => {
    if (!values[field]) {
      errors[field] = 'Please fill out the field'
    }
  })
  if (values.email && !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)) {
    errors.email = 'Wrong email address'
  }
  return errors
}

const renderTextField = ({label, input, meta: {touched, invalid, error}, ...custom}) => (
  <TextField
    label={label}
    placeholder={label}
    error={touched && invalid}
    helperText={touched && error}
    {...input}
    {...custom}
  />
)

const renderFromHelper = ({touched, error}) => {
  if (!(touched && error)) {
  } else {
    return <FormHelperText>{touched && error}</FormHelperText>
  }
}

const renderSelectField = ({input, label, meta: {touched, error}, children, ...custom}) => (
  <FormControl error={touched && error}>
    <InputLabel htmlFor="age-native-simple">Age</InputLabel>
    <Select
      native
      {...input}
      {...custom}
      inputProps={{
        name: 'age',
        id: 'age-native-simple'
      }}
    >
      {children}
    </Select>
    {renderFromHelper({touched, error})}
  </FormControl>
)

const MaterialUiForm = (props) => {
  const [checked, setChecked] = React.useState(false)
  const [messageText, setMessageText] = React.useState('')
  const [mailSend, setMailSend] = React.useState(false)

  const [age, setAge] = React.useState('')

  const handleChangeDropDown = (event) => {
    setAge(event.target.value)
  }

  const handleChange = (event) => {
    setChecked(event.target.checked)
    console.log(checked)
  }

  const handleSubmit = () => {
    setMailSend(true)
    window.open(`mailto:contact@giobarto.com?subject=Contact Request&body=${messageText}`)
  }
  return mailSend ? (
    <Grid container spacing={5} justifyContent={'center'}>
      <Grid item xs={12} md={4} textAlign="center">
        <p>Thank you for your message!</p>
        You will be redirected to your mail client to send your mail to us!
      </Grid>
    </Grid>
  ) : (
    <form onSubmit={handleSubmit}>
      <Grid container spacing={5} justifyContent={'center'}>
        <Grid item xs={8} md={2} textAlign="center">
          <div style={{minWidth: '100%'}}>
            <InputLabel id="demo-simple-select-label">First Name</InputLabel>
            <Field name="firstName" component={renderTextField} label=" " />
          </div>
          <div className="my-3">
            <InputLabel id="demo-simple-select-label">Last Name</InputLabel>
            <Field name="lastName" component={renderTextField} label=" " />
          </div>
          <div className="my-3">
            <InputLabel id="demo-simple-select-label">Reasons for Contact</InputLabel>
            <Select
              sx={{
                width: '100%'
              }}
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={age}
              label="Select enquiry"
              onChange={handleChangeDropDown}
            >
              <MenuItem value={10}>Purchase request</MenuItem>
              <MenuItem value={20}>Information</MenuItem>
            </Select>
          </div>
        </Grid>
        <Grid
          item
          xs={8}
          md={5}
          textAlign="center"
          style={{paddingLeft: 0}}
          justifyContent={'center'}
        >
          <TextareaAutosize
            onChange={(event) => {
              setMessageText(event.target.value)
            }}
            aria-label="maximum height"
            placeholder="Please insert your message..."
            style={{
              padding: 10,
              width: 300,
              height: 300,
              borderStyle: 'solid',
              borderWidth: 2
            }}
          />
        </Grid>
        <Grid container justifyContent={'center'} marginTop={5}>
          <Checkbox
            style={{backgroundColor: 'white'}}
            checked={checked}
            onChange={handleChange}
            inputProps={{'aria-label': 'controlled'}}
          />
          <Grid item xs={8} md={7} textAlign="center">
            <Typography style={{color: 'black'}} gutterBottom>
              I have taken note of the privacy policy. I agree to electronic storage and processing
              of the data I have entered data for the purpose of responding to my inquiry. Note: The
              consent can revoked at any time for the future by sending an e-mail to
              contact@giobarto.com be.
            </Typography>
          </Grid>
        </Grid>
        <Grid item xs={12} md={12} textAlign="center">
          <Button
            style={{
              width: 150,
              backgroundColor: checked ? 'rgba(46,125,50,0.8)' : 'rgba(80,82,80,0.7)'
            }}
            disabled={!checked}
            type={'submit'}
            value="Submit"
          >
            <Typography style={{color: 'white', fontWeight: 700}} gutterBottom>
              Send Email
            </Typography>
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default reduxForm({
  form: 'MaterialUiForm', // a unique identifier for this form
  validate,
  asyncValidate
})(MaterialUiForm)
