import * as React from 'react'
import Home from './Pages/home'
import {Route, Routes} from 'react-router-dom'
import PageCatalogues, {SelectedCatalogue} from './Pages/catalogues'
import {AppProviders} from './providers'
import ScrollToTop from './Components/ScrolllToTop'
import PageAbout from './Pages/about_the_artist'
import PageKalender from './Pages/about_the_artist'
import PageEvents from './Pages/exhibitions'
import PageVeranstaltungen, {SelectedVeranstaltung} from './Pages/exhibitions'

import PageKontakt from './Pages/contact'
import PageImpressum from './Pages/impressum'
import PagePictures from './Pages/pictures'
import './index.css'
import PageFashion, {SelectedFashion} from './Pages/fashion'
export default function App() {
  return (
    <AppProviders>
      <ScrollToTop>
        <Routes>
          <Route path="/" element={<Home />} />
          {/*<Route path="gemeinde" element={<PageGemeinde />} />*/}

          <Route path="photographies" element={<PagePictures />} />
          {/*<Route path="aktivitaeten/:topicId" element={<SelectedCatalogue />} />*/}

          <Route path="fashion" element={<PageFashion />} />
          <Route path="fashion/:topicId" element={<SelectedFashion />} />

          <Route path="catalogues" element={<PageCatalogues />} />
          <Route path="catalogues/:topicId" element={<SelectedCatalogue />} />

          <Route path="/favorites" element={<PageKalender />} />
          <Route path="/cart" element={<PageKalender />} />

          <Route path="/exhibitions" element={<PageEvents />} />
          <Route path="exhibitions/:topicId" element={<SelectedVeranstaltung />} />
          <Route path="/about" element={<PageAbout />} />
          <Route path="/contact" element={<PageKontakt />} />
          <Route path="/impressum" element={<PageImpressum />} />
          <Route
            path="*"
            element={
              <main style={{padding: '1rem'}}>
                <p>Diese Seite ist nicht vorhanden!</p>
              </main>
            }
          />
        </Routes>
      </ScrollToTop>
    </AppProviders>

    // <Container maxWidth="sm">
    //   <Box sx={{ my: 4 }}>
    //     <Typography variant="h4" component="h1" gutterBottom>
    //       Create React App example with TypeScript
    //     </Typography>
    //     <ProTip />
    //     <Copyright />
    //   </Box>
    // </Container>
  )
}
