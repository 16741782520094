import * as React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'

import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import {makeStyles} from '@mui/styles'
import {Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  gameRoot: {
    position: 'relative',
    textAlign: 'center',
    alignSelf: 'center'
  },
  gameCover: {
    zIndex: 1,
    width: '100%',
    height: '100%'
  },
  MuiCardActionArea: {
    height: 'inherit',
    zIndex: 1
  }
}))
interface EventPreviewProps {
  event: {
    title: string
    date: string
    cover: any
    images: any
    markdown: any
    url: string
  }
}

export default function EventPreview(props: EventPreviewProps) {
  const {event} = props

  const classes = useStyles()
  return (
    <Grid item xs={10} md={4}>
      <Link to={`/exhibitions/${event.url}`}>
        <Card className={classes.gameRoot} elevation={3}>
          <CardContent
            sx={{
              flex: 1
            }}
          >
            <Typography component="h2" variant="h5">
              {event.title}
            </Typography>
            <Typography variant="subtitle1" color="primary">
              Datum: {event.date}
            </Typography>
          </CardContent>
          <CardMedia
            className={classes.gameCover}
            component="img"
            src={require(`./${event.cover}`)}
            alt={event.title}
          />
        </Card>
      </Link>
    </Grid>
  )
}
