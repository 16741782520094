import * as React from 'react'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Markdown from './Markdown'
import {useEffect, useState} from 'react'
import Button from '@mui/material/Button'
import BuyModal from './BuyModal'

interface MainProps {
  title: string
  markdown: any
  previewCalender?: boolean
  aboutArtists?: boolean
  purchaseitem?: any
  biggerFont?: boolean
}
export default function MarkdownToJsx(props: MainProps) {
  const [processedPost, setProcessedPostPost] = useState('')
  useEffect(() => {
    fetch(props.markdown)
      .then((res) => res.text())
      .then((res) => setProcessedPostPost(res))
      .catch((err) => console.log(err))
  })
  const [open, setOpen] = React.useState(false)

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleSend = () => {
    setOpen(false)
    window.open(
      `mailto:contact@giobarto.com?subject=Catalogue Request ${props.title}&body=${'messageText'}`
    )
  }

  const handleClose = () => {
    setOpen(false)
  }
  console.log('purchaseitem')
  console.log(props.purchaseitem)
  return (
    <Grid
      item
      xs={12}
      md={12}
      sx={{
        '& .markdown': {
          py: 3
        }
      }}
    >
      <div
        className="flex mb-2"
        style={{
          justifyContent: props.previewCalender ? 'space-between' : 'center',
          alignItems: 'center'
        }}
      >
        {props.aboutArtists ? null : <Typography variant="h3">{props.title}</Typography>}

        {props.previewCalender ? (
          <Button
            onClick={handleClickOpen}
            style={{width: 'fit-content', backgroundColor: '#f0e548'}}
          >
            <Typography variant="body1">Inquire Catalogue</Typography>
          </Button>
        ) : null}
      </div>
      {props.purchaseitem ? (
        <BuyModal
          open={open}
          handleClose={handleClose}
          handleSend={handleSend}
          purchaseCatalogue={props.purchaseitem}
          purchaseImage={props.purchaseitem}
          isImage={false}
        />
      ) : null}

      {props.aboutArtists ? null : <Divider />}
      <Markdown
        className="markdown"
        key={processedPost.substring(0, 40)}
        biggerFont={props.biggerFont}
      >
        {processedPost}
      </Markdown>
    </Grid>
  )
}
