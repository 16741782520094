import * as React from 'react'
import Box from '@mui/material/Box'
import Container from '@mui/material/Container'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import {Link} from 'react-router-dom'

export default function Footer() {
  return (
    <Box component="footer" sx={{mt: 10, pb: 6, pt: 3, width: '100%', backgroundColor: 'grey.100'}}>
      <Container maxWidth="lg">
        <Grid container spacing={5} sx={{mt: 3, mb: 0}}>
          <Grid item xs={12} md={4} textAlign="center">
            <Typography variant="h6" align="center" gutterBottom>
              Gio Barto
            </Typography>
            <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
              {'Copyright © '}
              Gio Barto {new Date().getFullYear()}
            </Typography>
            {/*<Typography*/}
            {/*    variant="subtitle1"*/}
            {/*    align="center"*/}
            {/*    color="text.secondary"*/}
            {/*    component="p"*/}
            {/*>*/}
            {/*    Designed by{' '}*/}
            {/*    <a*/}
            {/*        href="http://byteaffairs.com/"*/}
            {/*        target="_blank"*/}
            {/*        rel="noopener noreferrer"*/}
            {/*    >*/}
            {/*        ByteAffairs*/}
            {/*    </a>*/}
            {/*</Typography>*/}
          </Grid>
          <Grid item xs={12} md={4} textAlign="center">
            <Typography variant="h6" align="center" gutterBottom>
              Contact
            </Typography>
            <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
              E-Mail:{' '}
              <Link
                to="#"
                // @ts-ignore
                onClick={(e) => (window.location = 'mailto:contact@giobarto.com')}
              >
                contact@giobarto.com
              </Link>
            </Typography>
          </Grid>
          <Grid item xs={12} md={4} textAlign="center">
            <Typography variant="h6" align="center" gutterBottom>
              Legal notice
            </Typography>
            {/* <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
              <Link to={'impressum'} color="inherit">
                Imprint
              </Link>
            </Typography>
            <Typography variant="subtitle1" align="center" color="text.secondary" component="p">
              <Link to={'impressum'} color="inherit">
                Data protection
              </Link>
            </Typography> */}
          </Grid>
        </Grid>
      </Container>
    </Box>
  )
}
