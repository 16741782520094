import * as React from 'react'
import Typography from '@mui/material/Typography'
import {Link, useLocation} from 'react-router-dom'
import Logo from '../Assets/logo_new.gif'
import {Navbar} from 'react-bootstrap'
import {Button, Toolbar} from '@mui/material'
import {useMediaQuery} from 'react-responsive'
import InstagramIcon from '@mui/icons-material/Instagram'
import {Stack} from '@mui/material'
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder'
import ShoppingBasketIcon from '@mui/icons-material/ShoppingBasket'

const sections = [
  {title: 'Home', url: '/', id: 'home'},
  {title: 'Photographies', url: '/photographies', id: 'photographies'},
  {title: 'Fashion', url: '/fashion', id: 'fashion'},
  {title: 'Catalogues', url: '/catalogues', id: 'catalogues'},
  {title: 'Exhibitions', url: '/exhibitions', id: 'exhibitions'},
  {title: 'About the artist ', url: '/about', id: 'about'},
  {title: 'Contact', url: '/contact', id: 'contact', icon: null}
]

export default function Header() {
  let {pathname} = useLocation()
  let view = useLocation()
  console.log(view)
  console.log(pathname)
  let basePath = pathname.split('/')[1]
  const isPortrait = useMediaQuery({query: '(orientation: portrait)'})
  return !isPortrait ? (
    <div
      className="mt-1"
      style={
        pathname == '/'
          ? {
              zIndex: 100,
              position: 'absolute',
              left: 0,
              right: 0
            }
          : null
      }
    >
      <Navbar collapseOnSelect expand="sm" className="navbar-expand-sm justify-content-center">
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav "
          className="navbar-burger "
          id="toggle-connection"
        />
        <Navbar.Collapse
          className="navbar-tab-wrapper justify-content-center "
          id="responsive-navbar-nav"
        >
          <Toolbar sx={{padding: 0, justifyContent: 'center'}}>
            <Link to={'/'} style={{textDecoration: 'none'}}>
              <img src={Logo} className={'logo-img'} />
            </Link>
          </Toolbar>
          {sections.map((section, key, index) => (
            <Link style={{margin: 10, textDecoration: 'none '}} to={section.url} key={key}>
              <Typography
                fontWeight={pathname.split('/')[1] == section.id ? 700 : null}
                style={{fontSize: pathname == section.id ? 27 : 23}}
                color="black"
                align="center"
                noWrap
              >
                {section.icon ? section.icon : null} {section.title}
              </Typography>
            </Link>
          ))}
          <a
            style={{marginLeft: 10}}
            href={'https://www.instagram.com/gio.barto/'}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="https://img.icons8.com/fluency/43/000000/instagram-new.png" />
          </a>
        </Navbar.Collapse>
      </Navbar>
    </div>
  ) : (
    <div className="mt-2">
      <Navbar collapseOnSelect expand="sm" className="navbar-expand-sm ">
        <Link
          to={'/'}
          style={{
            textDecoration: 'none'
          }}
        >
          <img src={Logo} className={'logo-img'} />
        </Link>
        <div>
          {/* <ShoppingBasketIcon color={'primary'} fontSize={'medium'} style={{margin: '8px'}} />
          <FavoriteBorderIcon color={'primary'} fontSize={'medium'} style={{margin: '8px'}} /> */}
          <Navbar.Toggle
            aria-controls="responsive-navbar-nav"
            className="navbar-burger"
            id="toggle-connection"
          />
        </div>
        <Navbar.Collapse
          className="navbar-tab-wrapper justify-content-center "
          id="responsive-navbar-nav"
        >
          {sections.map((section, key, index) => (
            <Link style={{margin: 10, textDecoration: 'none '}} to={section.url} key={key}>
              <Typography
                fontWeight={pathname.split('/')[1] == section.id ? 700 : null}
                style={{fontSize: pathname == section.id ? 26 : 22}}
                color="black"
                align="center"
                noWrap
              >
                {section.icon ? section.icon : null} {section.title}
              </Typography>
            </Link>
          ))}
        </Navbar.Collapse>
      </Navbar>
    </div>
  )
}
