import {createTheme, responsiveFontSizes} from '@mui/material/styles'
import {red} from '@mui/material/colors'

// A custom theme for this app
let theme = createTheme({
    palette: {
        primary: {
            main: 'rgb(0,0,0)'
        },
        secondary: {
            main: 'rgba(217,78,42,0.71)'
        },
        error: {
            main: red.A400
        }
    },
    typography: {
        fontFamily: ['"Fanwood Text"', 'serif'].join(',')
    }
})
theme = responsiveFontSizes(theme)

export default theme
