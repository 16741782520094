import * as React from 'react'
import Carousel from 'react-bootstrap/Carousel'
import {useMediaQuery} from 'react-responsive'
import h_carousel0 from '../Assets/carousel/horizontal_0.jpg'
import h_carousel1 from '../Assets/carousel/horizontal_1.jpg'
import h_carousel2 from '../Assets/carousel/horizontal_2.jpg'
import h_carousel3 from '../Assets/carousel/horizontal_3.jpg'

import v_carousel0 from '../Assets/carousel/vertical_0.jpg'
import v_carousel1 from '../Assets/carousel/vertical_1.jpg'
import v_carousel2 from '../Assets/carousel/vertical_2.jpg'
import v_carousel3 from '../Assets/carousel/vertical_3.jpg'
import v_carousel4 from '../Assets/carousel/vertical_4.jpg'

export const CarouselComponent = () => {
  const isMobile = useMediaQuery({query: '(max-width: 500px)'})
  const isPortrait = useMediaQuery({query: '(orientation: portrait)'})
  const isTabletOrMobile = useMediaQuery({query: '(max-width: 1224px)'})

  return !isPortrait || !isMobile ? (
    <>
      <div className="" style={{height: '100vh'}}></div>
      <Carousel
        // interval={5000}
        controls={true}
        fade
        style={{width: '100vw', position: 'absolute', top: '0', left: '0'}}
        className={isTabletOrMobile ? 'remove-container-padding-tablet' : null}
      >
        <Carousel.Item key={0}>
          <img
            style={{
              height: '100vh',
              objectFit: 'contain'
            }}
            src={h_carousel2}
            alt="Second slide"
          />
        </Carousel.Item>
        <Carousel.Item key={1}>
          <img
            className="d-block "
            src={h_carousel0}
            alt="First slide"
            style={{
              height: '100vh',
              objectFit: 'contain'
            }}
          />
        </Carousel.Item>
        <Carousel.Item key={2}>
          <img
            className="d-block"
            src={h_carousel1}
            alt="Second slide"
            style={{
              height: '100vh',
              objectFit: 'contain'
            }}
          />
        </Carousel.Item>
        <Carousel.Item key={3}>
          <img
            className="d-block"
            src={h_carousel3}
            alt="Second slide"
            style={{
              height: '100vh',
              objectFit: 'contain'
            }}
          />
        </Carousel.Item>
      </Carousel>
    </>
  ) : (
    // remove-container-padding
    <Carousel interval={4000} controls={false} fade className="">
      <Carousel.Item key={0}>
        <img className="d-block w-100" src={v_carousel0} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item key={1}>
        <img className="d-block w-100" src={v_carousel3} alt="First slide" />
      </Carousel.Item>
      <Carousel.Item key={2}>
        <img className="d-block w-100" src={v_carousel2} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item key={3}>
        <img className="d-block w-100" src={v_carousel1} alt="Second slide" />
      </Carousel.Item>
      <Carousel.Item key={4}>
        <img className="d-block w-100" src={v_carousel4} alt="Second slide" />
      </Carousel.Item>
    </Carousel>
  )
}
