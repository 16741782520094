import * as React from 'react'
import {styled} from '@mui/material/styles'
import Card from '@mui/material/Card'
import CardHeader from '@mui/material/CardHeader'
import CardMedia from '@mui/material/CardMedia'
import CardContent from '@mui/material/CardContent'
import CardActions from '@mui/material/CardActions'
import Collapse from '@mui/material/Collapse'
import Avatar from '@mui/material/Avatar'
import IconButton, {IconButtonProps} from '@mui/material/IconButton'
import Typography from '@mui/material/Typography'
import {red} from '@mui/material/colors'
import FavoriteIcon from '@mui/icons-material/Favorite'
import ShareIcon from '@mui/icons-material/Share'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import {ImageInterface, CatalogueDetailsInterface} from '../Catalogue.types'
import Divider from '@mui/material/Divider'
import {Button} from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import {Link} from 'react-router-dom'
import BuyModal from '../../../Components/BuyModal'
interface ExpandMoreProps extends IconButtonProps {
  expand: boolean
}

const ExpandMore = styled((props: ExpandMoreProps) => {
  const {expand, ...other} = props
  return <IconButton {...other} />
})(({theme, expand}) => ({
  transform: !expand ? 'rotate(0deg)' : 'rotate(180deg)',
  marginLeft: 'auto',
  transition: theme.transitions.create('transform', {
    duration: theme.transitions.duration.shortest
  })
}))

interface ImageDetailsInterface {
  image: {
    original: string
    thumbnail: string
    data: ImageInterface
    catalogue: CatalogueDetailsInterface
  }
  currentIndex: number
}
export default function ImageDetails({image, currentIndex}: ImageDetailsInterface) {
  const [expanded, setExpanded] = React.useState(true)
  const [open, setOpen] = React.useState(false)
  const handleExpandClick = () => {
    setExpanded(!expanded)
  }
  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleSend = () => {
    setOpen(false)
    window.open(
      `mailto:contact@giobarto.com?subject=Purchas Canvas Nr.${
        image.data.id
      }&body=${'messageText'}`
    )
  }

  const handleClose = () => {
    setOpen(false)
  }
  console.log('image')
  console.log(image)
  console.log('currentIndex')
  console.log(currentIndex)
  return (
    <Card
      raised={false}
      sx={{
        // borderColor: 'black',
        // borderWidth: 0.5,
        boxShadow: '0px 0px 0px 0px !important'
      }}
    >
      <CardHeader
        sx={{alignSelf: 'center'}}
        title={
          <Typography variant="h5" align="center" gutterBottom>
            {image.data.title ? image.data.title : 'unnamed'}
          </Typography>
        }
        subheader={
          <>
            <Typography variant="body1" align="center" gutterBottom>
              Published: {image.catalogue.publishing_year} in "{image.catalogue.title}"
            </Typography>
            <div className="d-flex justify-center">
              <IconButton aria-label="settings">
                <Button
                  style={{width: 'fit-content', backgroundColor: '#f0e548'}}
                  onClick={handleClickOpen}
                >
                  <Typography variant="body2">Inquire Canvas</Typography>
                </Button>
              </IconButton>
            </div>
          </>
        }
      />
      <Divider />
      <CardActions disableSpacing>
        {/* <IconButton aria-label="add to favorites">
          <FavoriteIcon />
        </IconButton>
        <IconButton aria-label="share">
          <ShareIcon />
        </IconButton> */}
        {/* <ExpandMore
          expand={expanded}
          onClick={handleExpandClick}
          aria-expanded={expanded}
          aria-label="show more"
        >
          <ExpandMoreIcon />
        </ExpandMore> */}
      </CardActions>
      <CardContent>
        {image.catalogue ? (
          <BuyModal
            open={open}
            handleSend={handleSend}
            handleClose={handleClose}
            purchaseCatalogue={image.catalogue}
            purchaseImage={image}
            isImage={true}
          />
        ) : null}
        <div className="row ">
          <div className="col-6 justify-content-center text-center ">
            <Typography variant="h6" align="center" gutterBottom>
              Details of the Canvas
            </Typography>
            <Typography variant="body1" color="text.primary">
              Price: --
            </Typography>
            <Typography variant="body1" color="text.primary">
              Sizes:{' '}
              {image.data.size.width + ' cm (width)  x  ' + image.data.size.height + ' cm (height)'}
            </Typography>
            <Typography variant="body1" color="text.primary">
              Canvas-Id: {image.data.id}
            </Typography>
          </div>
          <div className="col-6 text-center">
            <Typography variant="h6" align="center" gutterBottom>
              Information on the Catalogue
            </Typography>
            <Typography variant="body1" color="text.primary">
              {image.catalogue.title}
            </Typography>
            <Typography variant="body1" color="text.primary">
              {image.catalogue.description}
            </Typography>
            <Link to={`/catalogues/${image.catalogue.href}`}>
              <Button
                style={{
                  width: 'fit-content',
                  backgroundColor: 'rgba(156,194,130,0.62)',
                  marginTop: 20
                }}
              >
                <Typography variant="body2">More details</Typography>
                {/* <LaunchIcon color={'primary'} fontSize={'small'} /> */}
              </Button>
            </Link>
          </div>
        </div>
      </CardContent>
    </Card>
  )
}
