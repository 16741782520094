import * as React from 'react'
import Grid from '@mui/material/Grid'
import MarkdownToJsx from '../../Components/MarkdownToJsx'
import catalogue_overview from '../catalogues/catalogue_overview.json'
import introductionMarkdown from './gemeindeMarkdown/introduction.md'
import all_pictures from './pictures_all.json'
import Typography from '@mui/material/Typography'
import PartnerComponent from './PartnerComponent'
import ImageGallery from 'react-image-gallery'
import 'react-image-gallery/styles/css/image-gallery.css'
import ImageDetails from '../catalogues/components/ImageDetails'
import {useMediaQuery} from 'react-responsive'
import {Divider} from '@mui/material'
const PagePictures = () => {
  const refImage = React.useRef(null)
  const [allPictures, setAllPictures] = React.useState(null)
  const [imageIndex, setImageIndex] = React.useState(0)
  const allCataloges = catalogue_overview
  const isMobile = useMediaQuery({query: '(max-width: 500px)'})
  React.useEffect(() => {
    const array = []
    allCataloges.forEach((catalogue) => {
      const catalogue_details = require(`../../Assets/catalogues/${catalogue.details}`)
      // allPictures.push(catalogue)
      catalogue_details.print_on_canvas.forEach((item) =>
        array.push({
          loading: 'lazy',
          thumbnailLoading: 'lazy',
          original: require(`../../Assets/catalogues/${catalogue_details.path}${item.url}`),
          thumbnail: require(`../../Assets/catalogues/${catalogue_details.path}${item.url}`),
          data: item,
          catalogue: catalogue_details
        })
      )
    })
    setAllPictures(array)
  }, [])

  React.useEffect(() => {}, [])

  const previewimages = []
  // all_pictures.forEach((item) =>
  //   previewimages.push({
  //     original: require(`../../../Assets/catalogues/${item}`),
  //     thumbnail: require(`../../../Assets/catalogues/${item}`)
  //   })
  // )

  // TODO: work on referencing image gallery to know where we are currently so figure out what data to display
  return (
    <div>
      <Typography variant="h3" textAlign={'center'} my={2}>
        Collection of Photographies
      </Typography>
      <Grid container spacing={2} sx={{mt: 1, mb: 7}} justifyContent="center">
        {allPictures ? (
          <>
            <Grid item xs={12} md={10} textAlign="center">
              <div style={{height: '50%'}}>
                <ImageGallery
                  // onClick={this._onImageClick.bind(this)}
                  items={allPictures}
                  lazyLoad={true}
                  thumbnailPosition={'bottom'}
                  showThumbnails={isMobile ? false : true}
                  ref={refImage}
                  showIndex={true}
                  slideDuration={700}
                  // slideInterval={4000}
                  startIndex={0}
                  // onThumbnailClick={() => console.log(refImage.current?.getCurrentIndex())}
                  // bulletOnClick={() => console.log(refImage.current?.getCurrentIndex())}
                  onSlide={() => setImageIndex(refImage.current?.getCurrentIndex())}
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <ImageDetails image={allPictures[imageIndex]} currentIndex={imageIndex} />
            </Grid>
            {/* <button onClick={() => console.log(refImage.current.getCurrentIndex())}>TEST</button> */}
          </>
        ) : null}
        {/* <Grid item xs={12} md={12} textAlign="center">
          <Typography variant="h4" gutterBottom>
            Artists partners
          </Typography>
          <Grid container spacing={5} marginY={5} justifyContent="center" alignItems="center">
            {partners.map((partner) => (
              <PartnerComponent key={partner.href} partner={partner} />
            ))}
          </Grid>
        </Grid> */}
      </Grid>
    </div>
  )
}
export default PagePictures
