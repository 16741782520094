import * as React from 'react'
import Grid from '@mui/material/Grid'
import MarkdownToJsx from '../../Components/MarkdownToJsx'

import {useEffect, useState} from 'react'
import EventPreview from './EventPreview'
import Typography from '@mui/material/Typography'
import Paper from '@mui/material/Paper'
import Divider from '@mui/material/Divider'
import {useParams} from 'react-router'
import ImageGallery from 'react-image-gallery'
const veranstaltungen = [
  {
    title: 'GO ON',
    url: 'go-on',
    date: '2022-10-01',
    cover: 'exhibitions/kryptos/cover.jpg',
    images: [],
    markdown: 'exhibitions/kryptos/introduction.md'
  },
  {
    title: 'Exhibitions at Spazio Kryptos',
    url: 'exhibitions-spazio-kryptos',
    date: '2010-08-01',
    cover: 'exhibitions/spazio_kryptos_old/cover.jpg',
    imagePath: 'exhibitions/spazio_kryptos_old/images/',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg'],
    markdown: 'exhibitions/spazio_kryptos_old/introduction.md'
  },
  {
    title: 'Carpeneta Mostra',
    url: 'carpeneta-mostra',
    date: '2010-08-01',
    cover: 'exhibitions/carpeneta_mostra/cover.jpg',
    imagePath: 'exhibitions/carpeneta_mostra/images/',
    images: ['1.jpg', '2.jpg', '3.jpg', '4.jpg', '5.jpg', '6.jpg', '7.jpg', '8.jpg', '9.jpg'],
    markdown: 'exhibitions/carpeneta_mostra/introduction.md'
  },
  {
    title: 'Allegorie di un Viaggio',
    url: 'Allegorie di un Viaggio',
    date: '2010-08-01',
    cover: 'exhibitions/fiera_milano/cover.jpg',
    imagePath: 'exhibitions/fiera_milano/images/',
    images: [
      '0.jpg',
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
      '7.jpg',
      '8.jpg',
      '9.jpg',
      '10.jpg',
      '11.jpg',
      '12.jpg',
      '13.jpg',
      '14.jpg',
      '15.jpg',
      '16.jpg',
      '17.jpg',
      '18.jpg',
      '19.jpg',
      '20.jpg',
      '21.jpg',
      '22.jpg',
      '23.jpg',
      '24.jpg',
      '25.jpg',
      '26.jpg',
      '27.jpg',
      '28.jpg',
      '29.jpg',
      '30.jpg',
      '31.jpg',
      '32.jpg',
      '33.jpg',
      '34.jpg',
      '35.jpg',
      '36.jpg',
      '37.jpg',
      '38.jpg',
      '39.jpg',
      '40.jpg',
      '41.jpg',
      '42.jpg',
      '43.jpg',
      '44.jpg',
      '45.jpg',
      '46.jpg',
      '47.jpg',
      '48.jpg',
      '49.jpg',
      '50.jpg',
      '51.jpg',
      '52.jpg',
      '53.jpg',
      '54.jpg',
      '55.jpg',
      '56.jpg',
      '57.jpg',
      '58.jpg',
      '59.jpg',
      '60.jpg',
      '61.jpg',
      '62.jpg',
      '63.jpg',
      '64.jpg',
      '65.jpg',
      '66.jpg',
      '67.jpg',
      '68.jpg',
      '69.jpg',
      '70.jpg',
      '71.jpg',
      '72.jpg',
      '73.jpg',
      '74.jpg',
      '75.jpg',
      '76.jpg'
    ],
    markdown: 'exhibitions/fiera_milano/introduction.md'
  },
  {
    title: 'Wave Gallery',
    url: 'wave-gallery',
    date: '2010-08-01',
    cover: 'exhibitions/wave_gallery/cover.jpg',
    imagePath: 'exhibitions/wave_gallery/images/',
    images: [
      '0.jpg',
      '1.jpg',
      '2.jpg',
      '3.jpg',
      '4.jpg',
      '5.jpg',
      '6.jpg',
      '7.jpg',
      '8.jpg',
      '9.jpg',
      '10.jpg',
      '11.jpg',
      '12.jpg',
      '13.jpg',
      '14.jpg',
      '15.jpg',
      '16.jpg'
    ],
    markdown: 'exhibitions/wave_gallery/introduction.md'
  }
]

export default function PageVeranstaltungen() {
  const [upcomingEvent, setUpcomingEvent] = useState(null)
  const [furtherUpcomingEvent, setFurtherUpcomingEvent] = useState([])

  useEffect(() => {
    const closestEvent = veranstaltungen
      .filter((item) => new Date(item.date) >= new Date())
      // @ts-ignore
      .sort((a, b) => new Date(a.date) - new Date(b.date))
    console.log('closestEvent')
    console.log(closestEvent[0])
    if (closestEvent.length > 0) {
      setUpcomingEvent(closestEvent[0])
    }
    setFurtherUpcomingEvent(
      veranstaltungen
      // .filter((item) => new Date(item.date) >= new Date())
      // .filter((item) => item.title !== closestEvent[0].title)
    )
  }, [])
  console.log('furtherUpcomingEvent')
  console.log(furtherUpcomingEvent.length)
  console.log(furtherUpcomingEvent)
  console.log('upcomingEvent')
  console.log(upcomingEvent)
  return (
    <div className="">
      <Grid
        container
        justifyContent="center"
        // textAlign={'center'}
        spacing={5}
        sx={{mb: 7}}
      >
        <Grid item xs={12} md={12} className="text-center">
          <Typography variant="h3" fontWeight="500" my={2}>
            Upcoming Exhibitions
          </Typography>
        </Grid>
        {upcomingEvent !== null ? (
          <>
            <Grid item xs={8} md={4}>
              <img
                className="d-block w-100"
                src={require(`./${upcomingEvent.cover}`)}
                alt="First slide"
              />
            </Grid>
            <Grid item xs={12} md={8} className="presenter">
              <MarkdownToJsx
                markdown={require(`./${upcomingEvent.markdown}`)}
                title={upcomingEvent.title}
                biggerFont={true}
                // purchaseitem={upcomingEvent}
                // videoLink={'oOoBH6S_-sY'}
                // buttonLink={upcomingEvent.buttonLink}
              />
            </Grid>
          </>
        ) : (
          <Paper
            elevation={3}
            sx={{
              marginTop: 2,
              height: 150,
              width: 300,
              padding: 3,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            <div className="text-center">
              <Typography variant="h5">No upcoming exhibitions</Typography>
            </div>
          </Paper>
        )}
      </Grid>

      {/* <Grid container spacing={4} justifyContent="center" marginBottom={5}>
        <Grid item xs={12} md={12}>
          <Typography variant="h4" textAlign={'center'}>
            Further upcoming events
          </Typography>
        </Grid>

        {furtherUpcomingEvent != null && furtherUpcomingEvent.length > 0 ? (
          furtherUpcomingEvent.map((event) => <EventPreview key={event.title} event={event} />)
        ) : (
          <Paper
            elevation={3}
            sx={{
              marginTop: 2,
              height: 150,
              width: 300,
              padding: 3,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            <div className="text-center">
              <Typography variant="h5">No upcoming events</Typography>
            </div>
          </Paper>
        )}
      </Grid> */}

      <Grid container justifyContent="center" marginTop={5} spacing={4}>
        <Grid item xs={12} md={12}>
          <Typography variant="h3" textAlign={'center'} sx={{marginBottom: 1}}>
            Past exhibitions
          </Typography>
        </Grid>

        {veranstaltungen
          .filter((item) => new Date(item.date) <= new Date())
          // @ts-ignore
          .sort((a, b) => new Date(b.date) - new Date(a.date))
          .map((event) => (
            <EventPreview key={event.title} event={event} />
          ))}
      </Grid>
    </div>
  )
}

export function SelectedVeranstaltung() {
  let {topicId} = useParams()
  const [selectedEvent, setSelectedEvent] = useState(null)
  const [exhibitionPictures, setExhibitionPictures] = React.useState(null)
  const [pastEvents, setPastEvents] = useState(null)
  const [futureEvents, setFutureEvents] = useState(null)
  useEffect(() => {
    if (topicId) {
      const selectedEvent = veranstaltungen.find((item) => item.url == topicId)
      console.log(selectedEvent)
      const array = []
      selectedEvent.images.forEach((item) =>
        array.push({
          original: require(`./${selectedEvent.imagePath}${item}`)
        })
      )
      console.log('selectedEvent')
      console.log(selectedEvent)
      setExhibitionPictures(array)
      setSelectedEvent(selectedEvent)
    }
    setFutureEvents(
      veranstaltungen
        .filter((item) => item.url != topicId)
        .filter((item) => new Date(item.date) >= new Date())
        // @ts-ignore
        .sort((a, b) => new Date(b.date) - new Date(a.date))
    )

    setPastEvents(
      veranstaltungen
        .filter((item) => item.url != topicId)
        .filter((item) => new Date(item.date) <= new Date())
        // @ts-ignore
        .sort((a, b) => new Date(b.date) - new Date(a.date))
    )
  }, [topicId])

  console.log(veranstaltungen)
  return selectedEvent ? (
    <div className="">
      <Grid justifyContent="center" container spacing={5} sx={{mt: 3, mb: 7}}>
        <Grid item xs={10} md={5}>
          <img
            className="d-block w-100"
            src={require(`./${selectedEvent.cover}`)}
            alt="First slide"
          />
        </Grid>
        <Grid item xs={12} md={7} className="presenter" textAlign={'left'}>
          <MarkdownToJsx
            markdown={require(`./${selectedEvent.markdown}`)}
            title={selectedEvent.title}
            biggerFont={true}
          />
        </Grid>
        {exhibitionPictures.length > 0 ? (
          <Grid item xs={12} md={9} textAlign="center">
            <Typography variant="h4" textAlign={'center'}>
              Impressions from the exhibitions
            </Typography>
            <div style={{height: '50%'}}>
              <ImageGallery
                // onClick={this._onImageClick.bind(this)}
                items={exhibitionPictures}
                lazyLoad={true}
                thumbnailPosition={'bottom'}
                showThumbnails={false}
                showIndex={true}
                slideDuration={700}
                // slideInterval={4000}
                startIndex={0}
              />
            </div>
          </Grid>
        ) : null}
      </Grid>
      {/* <Grid container spacing={4} justifyContent="center" marginTop={5}>
        <Grid item xs={12} md={12}>
          <Typography variant="h3" textAlign={'center'}>
            Further upcoming events
          </Typography>
        </Grid>

        {futureEvents.length > 0 ? (
          futureEvents.map((event) => <EventPreview key={event.title} event={event} />)
        ) : (
          <Paper
            elevation={3}
            sx={{
              marginTop: 2,
              height: 150,
              width: 300,
              padding: 3,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            <div className="text-center">
              <Typography variant="h5">No upcoming events</Typography>
            </div>
          </Paper>
        )}
      </Grid> */}
      <Grid container spacing={4} justifyContent="center" marginTop={5}>
        <Grid item xs={12} md={12}>
          <Typography variant="h4" textAlign={'center'}>
            Further past exhibitions
          </Typography>
        </Grid>
        {pastEvents.length > 0 ? (
          pastEvents.map((event) => <EventPreview key={event.title} event={event} />)
        ) : (
          <Paper
            elevation={3}
            sx={{
              marginTop: 2,
              height: 150,
              width: 300,
              padding: 3,
              alignItems: 'center',
              justifyContent: 'center',
              display: 'flex'
            }}
          >
            <div className="text-center">
              <Typography variant="h5">No past events</Typography>
            </div>
          </Paper>
        )}
      </Grid>
    </div>
  ) : (
    <div>This event was not found!</div>
  )
}
