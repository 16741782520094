import * as React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import {makeStyles} from '@mui/styles'
import {Link} from 'react-router-dom'
const useStyles = makeStyles((theme) => ({
  gameRoot: {
    position: 'relative',
    width: '100%',
    height: 50,
    textAlign: 'center'
  },
  gameCover: {
    zIndex: 1,
    height: 'inherit'
  },
  MuiCardActionArea: {
    height: 'inherit',
    zIndex: 1
  }
}))

interface PartnerProps {
  partner: {
    image: any
    href: string
    description: string
  }
}

export default function PartnerComponent(props: PartnerProps) {
  const {partner} = props

  const classes = useStyles()
  return (
    <Grid item xs={8} md={4}>
      <a href={partner.href} target="_blank" rel="noopener noreferrer">
        <div className={classes.gameRoot}>
          <img src={partner.image} />
        </div>
      </a>
      {/* <div className="mt-5 text-center">
        <Typography variant="h6" gutterBottom>
          {partner.description}
        </Typography>
      </div> */}
    </Grid>
  )
}
