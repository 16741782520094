import * as React from 'react'
import Grid from '@mui/material/Grid'
import {Provider} from 'react-redux'
import store from '../../Components/ContactForm/store'
import MaterialUiForm from '../../Components/ContactForm/ContactForm'
import showResults from '../../Components/ContactForm/showResults'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import emailjs from '@emailjs/browser'
import Button from '@mui/material/Button'

export default function PageKontakt() {
  return (
    <div>
      <Grid container spacing={5} justifyContent={'center'}>
        <Grid item xs={12} md={8} textAlign="center" marginY={5}>
          <Typography variant="h4" gutterBottom>
            Contact
          </Typography>
          <Typography variant="h5" gutterBottom>
            For business enquiries or any other questions, please reach out to me. I will contact
            you as soon as possible.
          </Typography>
          <Divider />
        </Grid>

        <Grid item xs={12} md={12} textAlign="center">
          {/*<Button*/}
          {/*    onClick={() =>*/}
          {/*        window.open(*/}
          {/*            'mailto:christof.kinkel@sobhani.de?subject=Kontaktaufnahme über Webseite'*/}
          {/*        )*/}
          {/*    }*/}
          {/*>*/}
          {/*    Email an potsdam@bahai.de*/}
          {/*</Button>*/}
          <Provider store={store}>
            <MaterialUiForm onSubmit={showResults} />
          </Provider>
        </Grid>
      </Grid>
    </div>
  )
}
