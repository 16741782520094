import * as React from 'react'
import Grid from '@mui/material/Grid'
import {CarouselComponent} from '../../Components/CarouselComponent'
import MarkdownToJsx from '../../Components/MarkdownToJsx'
import introduction from './aboutMarkdown/introduction.md'

import CataloguePreview from './CataloguePreview'
import Typography from '@mui/material/Typography'
import InstagramIcon from '@mui/icons-material/Instagram'
import FacebookIcon from '@mui/icons-material/Facebook'
import {Link} from 'react-router-dom'
import {Divider, Stack} from '@mui/material'
import catalogues from '../catalogues/catalogue_overview.json'
import partner_1 from '../../Assets/partners/crespi_brera.png'
import partner_2 from '../../Assets/partners/logokryptosmilano.png'
import PartnerComponent from './PartnerComponent'

const socialMedia = [
  {name: 'Instagram', icon: InstagramIcon, href: 'https://www.instagram.com/gio.barto/'},
  {name: 'Facebook', icon: FacebookIcon, href: 'https://www.instagram.com/test/'}
]
const partners = [
  {
    href: 'https://www.crespibrera.com/',
    image: partner_1,
    description: 'Art'
  },
  {
    href: 'http://www.kryptosmateria.it/',
    image: partner_2,
    description: 'Partnered Galery'
  }
]
export default function PageAbout() {
  return (
    <div>
      <main>
        <div className="">
          <Typography variant="h3" textAlign={'center'} my={2}>
            About the Artist
          </Typography>
          <Divider />
          <Grid
            container
            justifyContent={'space-between'}
            spacing={5}
            sx={{mt: 1, mb: 7, paddingTop: 0}}
          >
            <Grid item xs={12} md={8}>
              <Grid container spacing={5} justifyContent={'center'}>
                <MarkdownToJsx
                  markdown={introduction}
                  title={'About the artist'}
                  aboutArtists={true}
                />
              </Grid>
            </Grid>
            <Grid item xs={4} md={4} textAlign="center" alignSelf={'center'}>
              <div style={{borderColor: 'black', borderWidth: '1px'}}>
                <img src={require('../../Assets/artist.jpg')} />
              </div>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} textAlign="center">
            <Typography variant="h4" gutterBottom>
              Partners of the Artist
            </Typography>
            <Grid container spacing={5} marginY={3} justifyContent="center" alignItems="center">
              {partners.map((partner) => (
                <PartnerComponent key={partner.href} partner={partner} />
              ))}
            </Grid>
          </Grid>
          {/*<Grid container spacing={4} justifyContent="center">*/}
          {/*    {featuredPosts.map((post) => (*/}
          {/*        <FeaturedPost key={post.title} post={post} />*/}
          {/*    ))}*/}
        </div>
      </main>
    </div>
  )
}
