import * as React from 'react'
import {createTheme, ThemeProvider} from '@mui/material/styles'
import Container from '@mui/material/Container'
import CssBaseline from '@mui/material/CssBaseline'
import Header from '../Components/Header'
import Home from '../Pages/home'
import Footer from '../Components/Footer'
import theme from '../theme'
import Alert from '@mui/material/Alert'
import ParentAdvice from '../Components/ParentAdvice'

interface ProviderProps {
  children: React.ReactNode
}

export const AppProviders: React.FC<ProviderProps> = ({children}) => {
  return (
    <ThemeProvider theme={theme}>
      <Container maxWidth="lg">
        {/* CssBaseline kickstart an elegant, consistent, and simple baseline to build upon. */}
        <CssBaseline />
        <Header />
        {children}
        <ParentAdvice />
      </Container>

      <Footer />
      <CssBaseline />
    </ThemeProvider>
  )
}
