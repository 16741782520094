import * as React from 'react'
import ReactMarkdown from 'markdown-to-jsx'
import Typography from '@mui/material/Typography'
import Link from '@mui/material/Link'
import Box from '@mui/material/Box'

function MarkdownListItem(props: any) {
  return <Box component="li" sx={{mt: 1, typography: 'body1'}} {...props} />
}

const options = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4'
      }
    },
    h2: {
      component: Typography,
      props: {gutterBottom: true, variant: 'h5'}
    },
    h3: {
      component: Typography,
      props: {gutterBottom: true, variant: 'subtitle1'}
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'caption',
        paragraph: true
      }
    },
    p: {
      component: Typography,
      props: {paragraph: true, fontSize: 18}
    },
    a: {component: Link},
    li: {
      component: MarkdownListItem
    }
  }
}
const optionsBigger = {
  overrides: {
    h1: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'h4'
      }
    },
    h2: {
      component: Typography,
      props: {gutterBottom: true, variant: 'h5'}
    },
    h3: {
      component: Typography,
      props: {gutterBottom: true, variant: 'subtitle1'}
    },
    h4: {
      component: Typography,
      props: {
        gutterBottom: true,
        variant: 'caption',
        paragraph: true
      }
    },
    p: {
      component: Typography,
      props: {paragraph: true, fontSize: 18, fontWeight: 600}
    },
    a: {component: Link},
    li: {
      component: MarkdownListItem
    }
  }
}

export default function Markdown(props: any) {
  return <ReactMarkdown options={props.biggerFont ? optionsBigger : options} {...props} />
}
