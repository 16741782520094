import * as React from 'react'
import {Alert, Button, Snackbar, Stack, Typography} from '@mui/material'

export default function ParentAdvice() {
  const [open, setOpen] = React.useState(true)

  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return
    }

    setOpen(false)
  }
  return (
    <Snackbar open={open} autoHideDuration={15000} onClose={handleClose}>
      <Alert onClose={handleClose} severity="warning" sx={{width: '100%'}}>
        <Typography style={{fontSize: 19}} color="black" justifySelf="center">
          The content of this webpage is restricted to adult viewing only, as it contains explicit
          content.
          <Button
            onClick={handleClose}
            style={{
              color: 'white',
              fontWeight: 'bold',
              // height: '5vh',
              padding: '10px',
              background: '#e08b14',
              marginLeft: '15px',
              marginRight: '5px'
            }}
          >
            Accept terms
          </Button>
        </Typography>
      </Alert>
    </Snackbar>
  )
}
