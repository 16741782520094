import * as React from 'react'
import Typography from '@mui/material/Typography'
import Grid from '@mui/material/Grid'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import CardContent from '@mui/material/CardContent'
import CardMedia from '@mui/material/CardMedia'
import {makeStyles} from '@mui/styles'
import {CardActions} from '@mui/material'
import LaunchIcon from '@mui/icons-material/Launch'
import {Link} from 'react-router-dom'

const useStyles = makeStyles((theme) => ({
  gameRoot: {
    position: 'relative',
    width: '100%',
    textAlign: 'center'
  },
  gameCover: {
    zIndex: 1,
    height: 'inherit'
  },
  MuiCardActionArea: {
    height: 'inherit',
    zIndex: 1
  }
}))

interface CataloguePreviewProps {
  catalogue: {
    details: string
    cover: any
  }
}

type Details = {
  href: string
  title: string
  publishing_year: string
  description: string
  catalogue_price: number
}

const CataloguePreview = (props: CataloguePreviewProps) => {
  const {catalogue} = props
  const classes = useStyles()
  const details: Details = require(`../../../Assets/catalogues/${catalogue.details}`)
  return (
    <Grid item xs={9} sm={5} md={4} justifyContent={'end'} style={{width: '100%'}}>
      <Link to={`/catalogues/${details.href}`}>
        <Card elevation={2} className={classes.gameRoot}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="194"
              image={require(`../../../Assets/catalogues/${catalogue.cover}`)}
              alt="Paella dish"
            />
            <CardContent
              style={{display: 'block'}}
              sx={{
                flex: 1
              }}
            >
              <Typography
                component="h2"
                variant="h5"
                color={'black'}
                // style={{borderRadius: 10, backgroundColor: 'rgba(227,111,111,0.92)'}}
              >
                {details.title}
              </Typography>
              <Typography
                component="p"
                variant="subtitle1"
                color={'black'}
                // style={{borderRadius: 10, backgroundColor: 'rgba(227,111,111,0.92)'}}
              >
                {`Published: ${details.publishing_year}`}
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions
            className="justify-content-center"
            // style={{backgroundColor: 'rgba(156,194,130,0.62)'}}
          >
            <Typography component="p" variant="h6" color={'black'} style={{marginRight: 10}}>
              More details
            </Typography>
            <LaunchIcon color={'primary'} fontSize={'small'} />
          </CardActions>
        </Card>
      </Link>
    </Grid>
  )
}
export default CataloguePreview
