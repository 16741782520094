import * as React from 'react'
import Grid from '@mui/material/Grid'
import {CarouselComponent} from '../../Components/CarouselComponent'
import MarkdownToJsx from '../../Components/MarkdownToJsx'
import introduction from './homeMarkdown/introduction.md'

import CataloguePreview from './CataloguePreview'
import Typography from '@mui/material/Typography'
import InstagramIcon from '@mui/icons-material/Instagram'
import {Stack} from '@mui/material'
import FacebookIcon from '@mui/icons-material/Facebook'
import {Link} from 'react-router-dom'
import catalogues from '../catalogues/catalogue_overview.json'

const socialMedia = [
  {name: 'Instagram', icon: InstagramIcon, href: 'https://www.instagram.com/gio.barto/'}
]
import PartnerComponent from '../about_the_artist/PartnerComponent'
import partner_1 from '../../Assets/partners/crespi_brera.png'
import partner_2 from '../../Assets/partners/logokryptosmilano.png'
import ParentAdvice from '../../Components/ParentAdvice'

const partners = [
  {
    href: 'https://www.crespibrera.com/',
    image: partner_1,
    description: 'Art'
  },
  {
    href: 'http://www.kryptosmateria.it/',
    image: partner_2,
    description: 'Partnered Galery'
  }
]
export default function PageHome() {
  return (
    <div>
      <main>
        <CarouselComponent />
        <div className="">
          <Grid container justifyContent={'space-between'} spacing={5} sx={{mt: 3, mb: 7}}>
            <Grid item xs={12} md={6}>
              <Typography variant="h3" textAlign={'center'} mb={3}>
                Artists catalogues
              </Typography>
              <Grid container spacing={5} justifyContent={'center'}>
                {catalogues
                  .sort((itemA, itemB) =>
                    Number(require(`../../Assets/catalogues/${itemA.details}`).publishing_year) <
                    Number(require(`../../Assets/catalogues/${itemB.details}`).publishing_year)
                      ? 1
                      : -1
                  )
                  .slice(0, 4)
                  .map((item, index) => (
                    <CataloguePreview key={index} catalogue={item} />
                  ))}
              </Grid>
              <Typography variant="h6" textAlign={'center'} gutterBottom sx={{mt: 3}}>
                Social Media
              </Typography>

              <a
                href={'https://www.instagram.com/gio.barto/'}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Stack direction="row" spacing={1} alignItems="center" justifyContent={'center'}>
                  <InstagramIcon color={'primary'} fontSize={'medium'} />
                  <span>Instagram</span>
                </Stack>
              </a>
            </Grid>
            <Grid item xs={12} md={6} textAlign="left">
              <MarkdownToJsx markdown={introduction} title={'About the artist'} />
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} textAlign="center" marginTop={10}>
            <Typography variant="h4" gutterBottom>
              Partners of the Artist
            </Typography>
            <Grid container spacing={5} marginY={3} justifyContent="center" alignItems="center">
              {partners.map((partner) => (
                <PartnerComponent key={partner.href} partner={partner} />
              ))}
            </Grid>
          </Grid>

          {/*<Grid container spacing={4} justifyContent="center">*/}
          {/*    {featuredPosts.map((post) => (*/}
          {/*        <FeaturedPost key={post.title} post={post} />*/}
          {/*    ))}*/}
        </div>
      </main>
    </div>
  )
}
